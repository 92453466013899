.wd-panel-full {
  background-position: center;
  background-size: cover;
  position: relative;
  // p {
  //   background: $black;
  //   color: $white;
  //   padding: 0.5em;
  //   font-style: italic;
  // }
  height: 100%;
}

.wd-panel-slug {
  font-size: 0.8rem;
  background: $black;
  color: $white;
  padding: 0.5em;
  font-style: italic;
  font-weight: lighter;
  letter-spacing: 1px;
  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}
