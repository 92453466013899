.wd-login-form-container {
  margin: 0px auto;
  text-align: center;
  width: 250px;
  @include media-breakpoint-up(sm) {
    width: 350px;
  }
  .login-links {
    margin-left: -1em;
    margin-right: -1em;
  }
  input {
    height: 50px;
  }
}
