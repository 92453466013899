.site-wrapper {
  min-height: 100%;
  margin-bottom: -70px;
}

.site-footer {
  background-color: $walden-green;
  text-align: center;
  color: $white;
  p {
    // @include media-breakpoint-down(sm) {
    //   font-size: 0.8em;
    // }
    margin: 0;
  }
  padding: 1.5em;
}

.site-footer,
.push {
  height: 70px;
}

@media (max-width: 575.98px) {
  .site-footer {
    font-size: 0.8em;
    padding: 2em;
  }
}
