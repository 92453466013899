$alcohol-image-url: 'https://s3.amazonaws.com/walden-static-assets/images/istock-864704442+(1).jpg';
$alcohol-image-gradient: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

.dashboard-alcohol {
  background-position: center top !important;
  background-image: $alcohol-image-gradient, url($alcohol-image-url);
}

.dashboard-alcohol-wrapper {
  @extend .container;
  padding: 0px 15px;
  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}
