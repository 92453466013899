#addons ul {
  list-style-type: none;
  margin-left: 2em;
}

#addons ul li {
  display: inline-block;
  list-style-type: none;
  float: left;
}

#addons ul li a {
  padding: 10px 20px;
  background: #eee;
  text-decoration: none;
  color: #333;

  font-family: 'Futura LT W01 Book' !important;
}

#addons ul li a:hover {
  background: #e1e1e1;
}

.group-section {
  .card-title {
    font-size: 1em !important;
  }
}

.text {
  color: white;
  font-size: 13px;
  position: absolute;
  overflow: auto;
  top: 50%;
  left: 50%;
  right: -30%;
  opacity: 1;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.quantity {
  appearance: none;
  -webkit-appearance: none;
}

.addon-success-btn,
.addon-success-btn:hover {
  background: $success !important;
  border-color: $success !important;
}

.active-cat {
  background: #697873 !important;
  color: #fff !important;
}

.active-cat:hover {
  background: #ff6900 !important;
  color: #fff !important;
}

#addon-cart {
  padding: 0em;
  border: 1px solid #eee;
  margin-bottom: 1em;
}

#addon-cart-row {
  margin-left: 0px;
}

#addon-cart-title {
  padding: 1em;
  background: #eee;
}

#addon-cart-content {
  padding: 1em;
}

#addon-content selecta:after {
  content: '' !important;
  border: 4px solid transparent !important;
  border-top: 4px solid rgb(51, 51, 51) !important;
  margin-left: 2px !important;
  margin-bottom: 3px !important;
  display: inline-block !important;
  vertical-align: bottom !important;
}

.img-container {
  position: relative;
  border-bottom: 1px solid #e4e4e4;
  height: 245px !important;
  background-position: center !important;
}

.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #000000;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  opacity: 0.8;
}

.img-container:hover .overlay {
  bottom: 0;
  height: 100%;
}

.addon-large img {
  // height: 200px !important;
  // border-bottom: 1px solid #e4e4e4;
}

.addon-large select {
  width: 100%;
}

.droparrow {
  position: absolute;
  right: 30px;
  bottom: 31px;
  pointer-events: none;
}

.droparrow-sm {
  position: absolute;
  right: 30px;
  pointer-events: none;
  bottom: 39px !important;
}

.cart-addon {
  // width: calc(94% / 3);
  display: inline-block;
  text-align: left;
  padding: 0.5em;
  /* border: 1px solid #eee; */
  // margin-left: -1px;
  // margin-top: -1px;
  // margin-right: 1em;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  i {
    &:hover {
      color: rgb(242, 65, 65);
    }
  }
}

.cart-addon i {
  float: right;
  padding: 3px;
}

.cart-addon:hover {
  background: rgb(249, 249, 249);
}

// .cart-addon:hover i {
//   color: rgb(242, 65, 65);
// }
