.wd-conf-check {
  width: 1.5em;
  height: 1.5em;
  border: 1px solid $black;
  position: relative;
  display: inline-block;
  margin: 0px;
  padding: 0px;
  &.active.wd-conf-mark-lg {
    display: block;
  }
}
.wd-conf-mark {
  position: absolute;
  width: 1.8em !important;
  left: -0.2em;
  top: -0.5em;
}
.wd-conf-mark-lg {
  position: absolute;
  top: -0.8em;
  left: -0.4em;
  width: 2.6em !important;
}
.wd-conf-check-box {
  padding: 0.5em;
  border: 2px solid $black;
  width: 100%;
  display: block;
  overflow: auto;
  .wd-conf-check {
    float: left;
    width: 1.2em;
    height: 1.2em;
    margin-top: 0.1em;
  }
}
.wd-conf-check-box-90 {
  @extend .wd-conf-check-box;
  width: 90%;
}
.wd-conf-check-text {
  display: inline-block;
  padding: 0 1em;
}
