.sc-section {
  @extend .container;
  @extend .px-2;
  @extend .py-4;

  @include media-breakpoint-down(xs) {
    h2 {
      font-size: 2em;
    }

    h5 {
      font-size: 1em;
    }
  }
}
