$share-type-border-transition: border 150ms ease-out;
$share-type-image-height: 3em;
$share-type-letter-spacing: 1px;

$share-type-mobile-height: 320px;
$share-type-slider-pagination-bg: $white;

.sc-share-type {
  border: 1px solid $walden-green-2;
  margin: 0;
  padding: 0;
  -webkit-transition: $share-type-border-transition;
  -moz-transition: $share-type-border-transition;
  -o-transition: $share-type-border-transition;
  transition: $share-type-border-transition;
  &.selected {
    border: 1px solid $walden-orange;
  }

  .mx-checkbox-standalone {
    background: $walden-green-3;
  }
}

.share-type-description {
  ul {
    list-style-type: disc;
  }
}

.share-type-disabled {
  opacity: 0.4;
}

.share-types-mobile {
  .share-type-slide {
    height: $share-type-mobile-height;
  }

  .swiper-pagination-bullet {
    background: $walden-green-3;
  }

  .swiper-pagination-bullet-active {
    background: $share-type-slider-pagination-bg;
  }
}

.share-type-image {
  height: $share-type-image-height;
}

.share-type-name {
  letter-spacing: $share-type-letter-spacing;
}

.wd-share-types-container {
  h3 {
    letter-spacing: 1px;
  }
  p {
    font-size: 1.4em;
    letter-spacing: 1px;
  }
}

.wd-share-type {
  background: $walden-green-2;
  padding: 2em;
  img {
    height: 3em;
    margin: 0px auto;
    display: block;
  }
  &.active {
    outline: 3px solid $walden-orange;
    .wd-share-type-selected {
      background-image: url('/assets/circle-checked.png');
    }
  }
  @include media-breakpoint-down(md) {
    height: 65vh;
    margin-left: 30%;
    margin-right: 30%;
    img {
      height: 15%;
    }
    &.active {
      outline: none;
      .wd-share-type-selected {
        background-image: url('/assets/circle-checked.png');
      }
    }
  }
  @include media-breakpoint-down(sm) {
    height: 65vh;
    margin-left: 25%;
    margin-right: 25%;
    img {
      height: 15%;
    }
  }
  @include media-breakpoint-down(xs) {
    height: 475px;
    margin-left: 15%;
    margin-right: 15%;
    img {
      height: 15%;
    }
  }
}

.wd-share-type-title {
  padding: 1em;
  letter-spacing: 1px;
  color: $walden-orange;
  text-align: center;
  @include media-breakpoint-down(md) {
    font-size: 2em;
  }
  @include media-breakpoint-down(sm) {
    font-size: 2em;
  }
  @include media-breakpoint-down(xs) {
    font-size: 1.8em;
  }
}
.wd-share-type-description {
  margin-top: 1em;
  margin-bottom: 4em;
  ul li {
    position: relative;
    color: #fff;
    font-size: 0.9em;
    &:not(:first-child):before {
      content: '.';
      vertical-align: middle;
      bottom: 0.3em;
      left: -0.5em;
      position: absolute;
    }
    @include media-breakpoint-down(md) {
      font-size: 2.5vh;
    }
    @include media-breakpoint-down(sm) {
      font-size: 2.5vh;
    }
    @include media-breakpoint-down(xs) {
      font-size: 2.3vh;
    }
  }
}
.wd-share-type-from {
  text-align: center;
  font-size: 0.7em;
  color: $white;
  vertical-align: middle;
  .large {
    font-size: 2em;
    vertical-align: sub;
    margin-top: -10px;
  }
  @include media-breakpoint-down(md) {
    text-align: center;
    font-size: 1.2em;
    .large {
      font-size: 1.5em;
    }
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.2em;
    .large {
      font-size: 1.5em;
    }
  }
  @include media-breakpoint-down(xs) {
    text-align: center;
    .large {
      font-size: 1.5em;
    }
  }
}

.wd-share-type-selected {
  width: 2em;
  height: 2em;
  display: block;
  margin: 0px auto;
  position: absolute;
  bottom: 2em;
  left: calc(50% - 1em);
  right: 50%;
  background-image: url('/assets/circle-unchecked.png');
  background-size: cover;
  background-repeat: no-repeat;
}

#share-type-carousel {
  @include media-breakpoint-down(md) {
    height: 72vh;
  }
  @include media-breakpoint-down(xs) {
    height: 525px;
  }
}

// mobile in landscape
@media (max-height: 576px) and (max-width: 1000px) and (orientation: landscape) {
  #share-type-carousel {
    height: 70vw;
  }

  .wd-share-type {
    height: 62vw;
    img {
      height: 15%;
    }
  }

  .wd-share-type-description {
    ul li {
      font-size: 2vw;
    }
  }
}

.wd-share-opts-how-much {
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 1px;
  border-bottom: 2px solid $walden-orange;
}

.wd-share-opts-num-servs {
  display: inline-block;
  letter-spacing: 1px;
}

.wd-share-price-prvw {
  font-size: 3em;
  color: $walden-orange;
  letter-spacing: 3px;
}

.wd-share-freq {
  vertical-align: super;
  display: inline-block;
  top: -0.2em;
  position: relative;
  color: $walden-orange;
  margin-left: 1em;
}

.wd-share-freq-check {
  padding: 0.3em;
  text-decoration: underline;
  cursor: pointer;
  position: inherit;
  top: 0;
  left: 0;
}
