@import 'account/payment-info';

.wd-account-header {
  display: block;
  text-align: center;
  text-transform: uppercase;
  border-top: 1px solid $white-3;
  border-bottom: 1px solid $white-3;
  padding: 1em;
  letter-spacing: 2px;
  margin-top: -1px;
  cursor: pointer;
}

.conf-footer {
  @include media-breakpoint-down(sm) {
    width: 100% !important;
    display: block !important;
    text-align: center;
  }
}
