.wd-select {
  display: inline-block !important;
  width: 15em;
  // border: 2px solid $black !important;
  border: 1px solid #888888;
  background: url(/assets/walden-down-arrow-c.png) no-repeat 95% 57% $white !important;
  background-size: 1.5em !important;
  height: 45px;
  padding: 10px;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: $white;
  outline: none;
}
