$instruction-margin: 25px;
.mx-x-recipe-instruction-list {
  ul {
    /** Using a CSS counter instead of a template-index for more compatible styling. */
    counter-reset: instruction-counter;
    list-style: none;
    li {
      margin-left: $instruction-margin;
      position: relative;
      &:before {
        content: counter(instruction-counter) '. ';
        counter-increment: instruction-counter;
        font-weight: bold;
        left: -$instruction-margin;
        position: absolute;
      }
    }
  }
}
