$container-height: 2rem;
$nav-padding-bottom: 2.5rem;
$nav-letter-spacing: 1px;
.mx-specials-navbar {
  .nav-container {
    height: $container-height;
    overflow: hidden;
  }
  .nav-main {
    letter-spacing: $nav-letter-spacing;
    overflow-x: auto;
    padding-bottom: $nav-padding-bottom;
    -webkit-overflow-scrolling: touch;
  }
}
