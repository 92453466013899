$referral-image: url('https://s3.amazonaws.com/walden-static-assets/images/Walden-Referral-Dash.png');
$referral-image-gradient: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

.dashboard-referrals {
  background-position: center bottom !important;
  background-image: $referral-image-gradient, $referral-image;
}
.dashboard-referrals-wrapper {
  @extend .container;
  padding: 0px 15px 0px 15px;
  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}

.referrals-header {
  padding-top: 100px;
  font-size: 1.6em !important;
  @include media-breakpoint-up(md) {
    font-size: 2.8em !important;
  }
  @include media-breakpoint-up(lg) {
    font-size: 4em !important;
  }
}
