.wd-member-nav {
  height: 50px;
  background: $walden-green-3;
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    display: inline-block;
    li {
      list-style-type: none;
      display: inline-block;
      a {
        display: inline-block;
        color: #fff;
        text-decoration: none;
        padding: 10px 18px 13px 18px;
        text-transform: uppercase;
        transition: all 0.2s ease-out;
        cursor: pointer;
        font-size: 14px;
        margin-top: 6px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        &:hover {
          transition: all 0.2s ease-out;
          background: $walden-green-4;
        }
        &.active {
          background: $white;
          color: $walden-orange;
        }
      }
    }
  }
}
