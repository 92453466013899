.example.example2 {
  background-color: #fff !important;
  width: 100% !important;
}

.example.example2 * {
  // font-family: Source Code Pro, Consolas, Menlo, monospace !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.example.example2 .row {
  // display: -ms-flexbox !important;
  // display: flex !important;
  // margin: 0 5px 10px !important;
}

.example.example2 .field {
  position: relative !important;
  width: 100% !important;
  height: 50px !important;
  // margin: 0 10px !important;
}

.example.example2 .field.half-width {
  width: 50% !important;
}

.example.example2 .field.quarter-width {
  width: calc(25% - 10px) !important;
}

.example.example2 .baseline {
  position: absolute !important;
  width: 100% !important;
  height: 1px !important;
  left: 0 !important;
  bottom: 0 !important;
  background-color: #cfd7df !important;
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}

.example.example2 label {
  position: absolute !important;
  width: 100% !important;
  left: 0 !important;
  bottom: 8px !important;
  color: #cfd7df !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  transform-origin: 0 50% !important;
  cursor: text !important;
  transition-property: color, transform !important;
  transition-duration: 0.3s !important;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}

.example.example2 .input {
  position: absolute !important;
  width: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
  padding-bottom: 7px !important;
  color: #32325d !important;
  background-color: transparent !important;
}

.example.example2 .input::-webkit-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}

.example.example2 .input::-moz-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}

.example.example2 .input:-ms-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}

.example.example2 .input.StripeElement {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  will-change: opacity;
}

.example.example2 .input.focused,
.example.example2 .input:not(.empty) {
  opacity: 1 !important;
}

.example.example2 .input.focused::-webkit-input-placeholder,
.example.example2 .input:not(.empty)::-webkit-input-placeholder {
  color: #cfd7df !important;
}

.example.example2 .input.focused::-moz-placeholder,
.example.example2 .input:not(.empty)::-moz-placeholder {
  color: #cfd7df !important;
}

.example.example2 .input.focused:-ms-input-placeholder,
.example.example2 .input:not(.empty):-ms-input-placeholder {
  color: #cfd7df !important;
}

.example.example2 .input.focused + label,
.example.example2 .input:not(.empty) + label {
  color: #aab7c4 !important;
  transform: scale(0.85) translateY(-25px) !important;
  cursor: default !important;
}

.example.example2 .input.focused + label {
  color: #24b47e !important;
}

.example.example2 .input.invalid + label {
  color: #ffa27b !important;
}

.example.example2 .input.focused + label + .baseline {
  background-color: #24b47e !important;
}

.example.example2 .input.focused.invalid + label + .baseline {
  background-color: #e25950 !important;
}

.example.example2 input,
.example.example2 button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  outline: none !important;
  border-style: none !important;
}

.example.example2 input:-webkit-autofill {
  -webkit-text-fill-color: #e39f48 !important;
  transition: background-color 100000000s !important;
  -webkit-animation: 1ms void-animation-out !important;
}

.example.example2 .StripeElement--webkit-autofill {
  background: transparent !important;
}

.example.example2 input,
.example.example2 button {
  -webkit-animation: 1ms void-animation-out !important;
}

.example.example2 button {
  display: block !important;
  width: calc(100% - 30px) !important;
  height: 40px !important;
  margin: 40px 15px 0 !important;
  background-color: #24b47e !important;
  border-radius: 4px !important;
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.example.example2 input:active {
  background-color: #159570 !important;
}

.example.example2 .error svg {
  margin-top: 0 !important;
}

.example.example2 .error svg .base {
  fill: #e25950 !important;
}

.example.example2 .error svg .glyph {
  fill: #fff !important;
}

.example.example2 .error .message {
  color: #e25950 !important;
}

.example.example2 .success .icon .border {
  stroke: #abe9d2 !important;
}

.example.example2 .success .icon .checkmark {
  stroke: #24b47e !important;
}

.example.example2 .success .title {
  color: #32325d;
  font-size: 16px !important;
}

.example.example2 .success .message {
  color: #8898aa;
  font-size: 13px !important;
}

.example.example2 .success .reset path {
  fill: #24b47e !important;
}
