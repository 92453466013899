.wd-zip-form-container {
  margin: 0px auto;
  background: $white;
  text-align: center;
  width: 300px;
  @include media-breakpoint-up(sm) {
    width: 350px;
  }
  input {
    height: 50px;
  }

  .form-desc {
    margin-left: 0px;
    margin-right: 0px;
    @include media-breakpoint-up(sm) {
      margin-left: -1.75em;
      margin-right: -1.75em;
    }
  }

  p {
    &.inform {
      color: black;
      font-size: 0.8em;
    }
    &.have-account {
      color: $gray;
    }
  }
}
