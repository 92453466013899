@import 'members/monthly-addons';

.hero-text {
  font-weight: bold;
  font-size: 2em;
  color: #fff;
  text-shadow: 0px 0px 5px #696969;
  letter-spacing: 5px;
  @include media-breakpoint-down(sm) {
    font-size: 1em;
  }
}

.sidekick-text {
  font-weight: bold;
  font-size: 2em;
  color: #fff;
  text-shadow: 0px 0px 5px #696969;
  @include media-breakpoint-down(sm) {
    font-size: 1em;
  }
}

.holiday-hero {
  background-size: cover;
  position: relative;
  background-position: center center;
  height: 48vh;
  .farmer-credit {
    font-size: 0.8em;
    position: absolute !important;
    bottom: 0 !important;
    left: 50%;
    transform: translateX(-50%);
  }
  .hero-content {
    position: absolute;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .hero-text-small {
    font-size: 2em !important;
    @include media-breakpoint-down(sm) {
      font-size: 1em !important;
    }
  }
}

.holiday-hero span.font-heavy {
  display: block;
  font-size: 4em;
  @include media-breakpoint-down(sm) {
    font-size: 1.3em;
  }
  color: #ff6900;
  letter-spacing: 8px;
  line-height: 1.2em;
}

#refer-container {
  @include media-breakpoint-down(sm) {
    margin-top: 2em;
  }
}

.dash-callout {
  @include media-breakpoint-down(sm) {
    margin-top: 2em;
  }
}

.members-index {
  background: #efefef !important;
}
.members-index-banner {
  background: url('https://s3.amazonaws.com/walden-static-assets/images/tk2.jpg') no-repeat center center !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.blog-post-link {
  color: #333;
}

.vimeo-embed {
  position: relative;
  padding-bottom: 41.66666667%;
  overflow: hidden;
  max-width: 100%;
  height: 100%;
  overflow: auto !important;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
