.cart-btn {
  position: fixed;
  right: -5px;
  z-index: 1000;
  padding-right: 50px;
}

.truncate-multiline--button {
  margin      : 0;
    padding     : 0;
      border      : 0;
        background  : transparent;
          font-family : inherit;
            font-size   : 1em;
              cursor      : pointer;
              color: #555;
              display: block !important;
    width: 50%;
    margin: 0 auto;
}

.truncate-multiline--button:hover {
  text-decoration: underline;
}
