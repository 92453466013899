$container-border-width: 2px;

.cut-selection {
  .category.mx-container-sm {
    border-width: $container-border-width !important;
  }
}

.wd-cut-selection-container {
  letter-spacing: 1px;
  p {
    font-size: 1.4em;
  }
}

.wd-cut-selection-cow-container {
  padding-left: 6em;
  padding-right: 6em;
  @include media-breakpoint-down(lg) {
    padding-left: 4em;
    padding-right: 4em;
  }
  @include media-breakpoint-down(md) {
    padding-left: 2em;
    padding-right: 2em;
  }
}

.wd-cut-selection-cow-name {
  text-align: center;
  position: relative;
  top: -18px;
  font-size: 0.8em;
  span {
    width: 115px;
  }
}

.wd-cut-selection-cow {
  width: 115px;
  display: inline-block;
  margin-top: -75px;
}

.wd-cut-group {
  margin-bottom: 1em;
  &.selected {
    border-color: $walden-orange;
  }
}

.wd-cut-group-base {
  .wd-cut-group {
    width: 100%;
  }
}
.wd-cut-group-name {
  margin-left: 2.5em;
}

.wd-cut-group-popover {
  outline: none;
  display: block;
  width: 3em;
  height: 2em;
  z-index: 9999;
  position: absolute;
  right: 1em;
  top: 4.4em;
  color: $gray;
  i {
    position: relative;
    left: 1.1em;
  }
}

.wd-cut-group-subs {
  padding-bottom: 1em;
  background-image: url('/assets/walden-down-arrow.png');
  .wd-cut-group-subs-arrow {
    display: inline-block;
    width: 3em;
  }
}
