.wd-share {
  border: 1px solid $white-5;
}

.wd-next-share-details {
  p {
    margin-bottom: 0px;
  }
}

.wd-next-share-estimate {
  border-right: 1px solid $white-5;
  background-color: $white-2;
  @include media-breakpoint-down(sm) {
    border-right: none;
  }
}
