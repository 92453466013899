.wd-main-nav {
  background: $walden-green;
  .nav-item {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.wd-main-nav-image {
  cursor: pointer;
  height: 60px;
  width: 100px;
  background: url('/images/walden-logo-dark.svg');
  background-size: 100px 60px;
}

.wd-main-nav-welcome {
  padding: 0.5em;
  display: block;
}
