$img-min-width: 100px;
$img-min-width-sm: 150px;
$img-min-width-md: 250px;
.mx-atk-content-group-card {
  .group-card-title {
    &:hover {
      text-decoration: underline;
    }
  }
  .group-card-image {
    img {
      @extend .rounded;
      /** Prevents the image from continuously shrinking on small devices. **/
      max-width: $img-min-width;
      min-width: $img-min-width;
      @include media-breakpoint-up(sm) {
        max-width: $img-min-width-sm;
        min-width: $img-min-width-sm;
      }
    }
  }
}
