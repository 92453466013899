.wd-input {
  margin-top: 1em;
  padding: 10px;
  height: 30px;
  transition: all 0.2s ease-out;
  display: block;
  height: 50px;
  border: 1px solid $gray-2;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px $walden-orange;
    transition: all 0.2s ease-out;
  }
}

.wd-input-error {
  color: $red;
  margin-top: 0.5em;
}

.ta-large {
  height: 175px !important;
  padding: 2em !important;
}
