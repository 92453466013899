.btn-next {
  @extend .btn-primary;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1em 4em;
  border-radius: 0px;
  letter-spacing: 1px;
  &.tall {
    margin-top: 5em;
    margin-bottom: 5em;
  }
}
