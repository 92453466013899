$recurring-addon-bg-left: url('/assets/addons/foodbg-left.png');
$recurring-addon-bg-right: url('/assets/addons/foodbg-right.png');
$recurring-addon-img-width: 120px;

.recurring-addons-wrapper {
  @include media-breakpoint-up(lg) {
    background-image: $recurring-addon-bg-left, $recurring-addon-bg-right;
    background-position: left center, right center;
    background-repeat: no-repeat, no-repeat;
  }

  img {
    width: $recurring-addon-img-width !important;
  }
}
