.edit-holiday-main {
  text-decoration: underline !important;
  cursor: pointer;
}

span.round-tab {
  width: 35px;
  height: 35px;
  line-height: 31px;
  display: inline-block;
  border-radius: 100px;
  background: #fff;
  border: 2px solid #e0e0e0;
  transition: border 0.1s linear;
  z-index: 2;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
}
span.round-tab {
  color: #555555;
}

span.round-tab:hover {
  transition: border-color 0.1s linear;
  border-color: #333;
}

span.round-tab.step-active:hover {
  border-color: #ff6900;
}

.wizard li.active span.round-tab {
  background: #fff;
  border: 2px solid #5bc0de;
}

.wizard-inner {
  display: none;
}

.round-tab.step-active {
  border-color: #ff6900;
}

.wizard hr {
  color: #eee;
  background: #eee;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid #ff6900;
  margin: 0px auto;
}

.seasonal-share .style-btn {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 500;
  color: #a6a6a6;
  border-radius: 2px;
  padding: 0.75rem 1.25rem;
  letter-spacing: 1px;
  text-decoration: none;
}

.seasonal-share .style-btn-gradient {
  background-color: #f2f2f2;
  transition: background 0.3s ease-in-out;
}
.seasonal-share .style-btn-gradient:hover {
  color: white;
  background-color: #ff6900;
}

.seasonal-share .card.promote {
  transform: scale(1.1);
  -webkit-box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.08);
}

.seasonal-share .style-btn-gragient.disabled:hover,
.seasonal-share .style-btn.disabled:hover,
.seasonal-share .btn.disabled:hover {
  background-color: #f2f2f2 !important;
  color: #a6a6a6 !important;
  pointer-events: none;
}
