.wd-expand {
  border: 2px solid $black;
  padding: 0.5em 1em;
  margin-bottom: 1em;
  letter-spacing: 1px;
  .wd-expand-arrow {
    display: block;
    width: 1em;
    float: right;
    padding-top: 8px;
  }
}
