$walden-orange: #ff6900;
$walden-orange-2: #f36c21;
$walden-green: #183028;
$walden-green-2: #2f443e;
$walden-green-3: #697873;
$walden-green-4: #5a6762;
$walden-gold: #d1af89;
$walden-brown: #97683e;

$white: #ffffff;
$white-2: #f4f4f4;
$white-3: #eeeeee;
$white-4: #d8d8d8;
$white-5: #e0e0e0;

$black: #333333;
$gray: #86928e;
$gray-2: #888888;

$red: #ff0000;
$red-2: #ff4545;
$red-3: #981312;

$blue: #5c95b2;
