$nav-height: 6rem;
$nav-padding-bottom: 2.5rem;
.mx-atk-content-group-nav-mobile {
  .nav-container {
    height: $nav-height;
    overflow: hidden;
  }
  .nav-main {
    overflow-x: auto;
    padding-bottom: $nav-padding-bottom;
    -webkit-overflow-scrolling: touch;
  }
}
