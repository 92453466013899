$img-cow: 'https://s3.amazonaws.com/walden-static-assets/images/IMG_20180122_133822.jpg';
$img-get-started: 'https://s3.amazonaws.com/walden-static-assets/images/walden_butcher_shop.jpg';
$image-craft: '/assets/addons/kraftbg.png';

.img-cow {
  background: url($img-cow);
  background-position: right;
}

.img-login {
  background: url('https://s3.amazonaws.com/walden-static-assets/images/Login_Module_RedsBest.jpg');
  background-position: right center !important;
}

.img-get-started {
  background: url($img-get-started);
}

.img-shop {
  background: linear-gradient(rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.69)),
    url('https://s3.amazonaws.com/walden-static-assets/images/login_splash_butcher.jpg');
}

.img-alcohol {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('https://s3.amazonaws.com/walden-static-assets/images/download.jpeg');
}

.img-seafood {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('https://s3.amazonaws.com/walden-static-assets/images/Doug+Feeney+-+JPEG.jpg');
}

.image-craft {
  background: url($image-craft);
}
