.thick {
  border: 2px solid $black !important;
}

.border-orange {
  border: 2px solid $walden-orange;
}

.border-green {
  border: 2px solid $walden-green;
}
