.wd-dietary-container {
  h3 {
    letter-spacing: 1px;
  }
  p {
    font-size: 1.4em;
    letter-spacing: 1px;
  }
}

.wd-dietary-select {
  text-align: center;
  margin: 6em auto;
  padding: 0.5em;
  font-size: 0.65em;
  @include media-breakpoint-up(sm) {
    font-size: 0.75em;
  }
  border: 2px solid $black;
  letter-spacing: 2px;
  position: relative;
  cursor: pointer;
}

.wd-dietary-arrow {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 3em;
  border-left: 2px solid $black;
}

.wd-dietary-down-arrow {
  background-image: url('/assets/walden-down-arrow.png');
  @extend .wd-dietary-arrow;
}

.wd-dietary-up-arrow {
  background: url('/assets/walden-up-arrow.png');
  @extend .wd-dietary-arrow;
}

.wd-dietary-popover {
  outline: none;
  display: block;
  width: 3em;
  height: 2em;
  z-index: 9999;
  position: absolute;
  right: 1em;
  top: 0.6em;
  color: $gray;
  i {
    position: relative;
    left: 1.1em;
  }
}
