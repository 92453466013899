.wd-monthly-addons-container {
  background: url('/assets/addons/kraftbg.png');
  background-size: contain;

  h3,
  p {
    letter-spacing: 1px;
  }
  p {
    font-size: 1.4em;
  }
}

.ignore-top {
  margin-top: 0 !important;
}

.addon-child-label {
  font-size: 1em !important;
}

.wd-monthly-addons-decorative-wrapper {
  width: 100%;
  background: url('/assets/addons/foodbg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px auto;
  @include media-breakpoint-down(sm) {
    background: none;
  }
}

.addon-description-2 {
  font-size: 1em !important;
}

.wd-monthly-addon {
  background: #f1f1f2;
  height: 100%;
}

.addon-image-2 {
  width: 36% !important;
}

.wd-monthly-addon-img {
  width: 50%;
  display: block;
  margin: 0px auto;
  @include media-breakpoint-down(sm) {
    width: 35%;
  }
}

.wd-monthly-addon-title {
  font-family: 'Futura LT W01 Heavy', sans-serif !important;
  color: #f36c21;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
}

.wd-monthly-addon-desc {
  text-align: center;
}

.wd-monthly-addon-quantity {
  padding: 2em !important; //over-ride 'no-gutters'
  padding-right: 3em !important;
}

.wd-monthly-addon-selection {
  margin-top: 30%;
  width: 100%;
  margin-bottom: 1em;
  @include media-breakpoint-down(md) {
    margin-top: 25%;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 0px;
  }
}

.wd-monthly-addon-per-month {
  color: $walden-orange;
  letter-spacing: 1px;
}

.wd-share-failure {
  background: #ff4545;
  color: #fff;
  padding: 1em;
  max-width: 1132px;
  margin: 1em auto;

  a {
    color: #fff;
    text-decoration: underline;
  }
}
