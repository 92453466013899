.wd-textarea {
  border: 1px solid $gray-2;
  padding: 15px;
  min-height: 120px;
  resize: none;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px $walden-orange;
    transition: all 0.2s ease-out;
  }
}
