.text-orange {
  color: $walden-orange;
}

.text-white {
  color: $white;
}

.text-gray {
  color: $gray;
}

.text-green-3 {
  color: $walden-green-3;
}

.text-red {
  color: $red;
}

.text-underline {
  text-decoration: underline;
}

.text-clean {
  letter-spacing: 2px;
}
