// Core Styles
@import 'fonts';
@import 'variables';
@import 'base';
@import 'utilities';
@import 'images';
@import 'layout';

// Bootstrap overrides
$theme-colors: (
  'primary': $walden-orange,
  'secondary': $walden-green-2
);

// Bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';

// Templates
@import 'templates';

// Components
@import 'components';

// Loaders
$primary-color: theme-color(primary);
@import 'ember-cli-loaders/loaders';

@import 'ember-power-select/themes/bootstrap';
@import 'ember-power-select';
