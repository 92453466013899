@import '../member-dashboards/recipes';
$s3-image-bucket: 'https://s3.amazonaws.com/walden-static-assets/images/';
$pyo-image-url: $s3-image-bucket + 'pyo_dash.jpg';

.tile-pyo {
  background-position: center left !important;
  background-size: cover;
  background-image: $recipes-image-gradient, url($pyo-image-url);
  height: 350px;
}
