$s3-image-bucket: 'https://s3.amazonaws.com/walden-static-assets/images/';
$recipes-image-url: $s3-image-bucket + 'recipe_dash.jpg';
$recipes-credit-url: $s3-image-bucket + 'cooks_logo.png';
$recipes-image-gradient: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

.dashboard-credit-logo {
  height: 60px;
  width: auto;
  background-image: url($recipes-credit-url);
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  @include media-breakpoint-up(md) {
    height: 100px;
  }
}

.dashboard-recipes {
  background-position: top !important;
  background-image: $recipes-image-gradient, url($recipes-image-url);
}

.dashboard-recipes-wrapper {
  font-size: 0.8em;
  @extend .container;
  padding: 0px 15px 0px 15px;
  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
  h4 {
    text-transform: none !important;
  }
}
