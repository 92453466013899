$checkbox-width: 25px;
$checkbox-height: 25px;
$checkbox-unchecked-bg: $mx-grey;
$checkbox-checked-bg: $mx-orange;
$checkbox-focus-bg: $mx-dark-grey;

.mx-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 0;
  margin-bottom: 0;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    color: $mx-white;
    text-align: center;
    height: $checkbox-height;
    width: $checkbox-width;
    background-color: $checkbox-unchecked-bg;
  }

  &:hover input ~ .checkmark {
    background-color: $checkbox-focus-bg;
  }

  input:checked ~ .checkmark {
    background-color: $checkbox-checked-bg;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}

.mx-checkbox-standalone {
  width: $checkbox-width;
  height: $checkbox-height;
  background: $mx-grey;

  &:hover {
    background: $checkbox-focus-bg;
  }

  &.checked {
    background: $mx-orange;
    color: $mx-white;
  }
}
