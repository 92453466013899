.wd-radio {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid $white-4;
  color: $blue;
  padding-top: 2px;
  padding-left: 0px;
  cursor: pointer;
  float: left;
  margin-right: 2em;
  .active {
    background: $blue;
  }
}

.wd-radio-inner {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: $white;
  margin: 0px auto;
  margin-top: 1px;
}
