.bg-green {
  background: $walden-green;
}

.bg-green-2 {
  background: $walden-green-2;
}

.bg-green-3 {
  background: $walden-green;
}

.bg-white-2 {
  background: $white-2;
}

.bg-white-3 {
  background: $white-3;
}

.bg-red-2 {
  background: $red-2;
}

.bg-red-3 {
  background: $red-3;
}

.bg-orange {
  background: $walden-orange;
}

.bg-alizarin {
  background: #e74c3c;
}

.bg-alt {
  background: #f3eee8;
}

.bg-tan {
  background: #e0dad2;
}

.bg-tan-2 {
  background-color: #f2f2ef;
}

.bg-brown {
  background-color: $walden-brown;
}
