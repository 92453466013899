#dash-loader {
  font-size: 0.5em;
  color: $orange;
}

.wd-dashboard-content {
  background-size: cover;
  background-position: center;
}

.wd-dashboard-content-inner {
  color: $white;
  font-size: 5em;
  letter-spacing: 0.1em;
  p {
    &:nth-child(2) {
      font-size: 0.4em;
    }
    span {
      color: $walden-orange;
    }
  }
  @include media-breakpoint-down(sm) {
    font-size: 3em;
  }
  @include media-breakpoint-down(xs) {
    font-size: 2.5em;
  }
}

.wd-dashboard-slug {
  @extend .wd-panel-slug;
  position: relative;
}
