$s3-image-bucket: 'https://s3.amazonaws.com/walden-static-assets/images/';
$fresh-pilot-image-url: $s3-image-bucket + 'Walden Meat14.jpg';
$fresh-image-gradient: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
.dashboard-fresh-pilot {
  background-position: center top !important;
  background-image: $fresh-image-gradient, url($fresh-pilot-image-url);
  a {
    letter-spacing: normal !important;
  }
}

.dashboard-fresh-pilot-wrapper {
  @extend .container;
  padding: 0px 15px 0px 15px;
  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}
