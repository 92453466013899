@import 'dashboard-builder/header';
@import 'dashboard-builder/content';
$dash-header-size-sm: 2em;
$dash-content-size-sm: 1em;
$dash-letter-spacing: 0.1em;

.dashboard-builder {
  background-size: cover;
  position: relative;
  background-position: center center;
  height: 300px;
  letter-spacing: $dash-letter-spacing;
  h4 {
    font-size: $dash-header-size-sm;
  }
  p {
    margin-bottom: 0px;
    font-size: $dash-content-size-sm;
  }
  @include media-breakpoint-up(md) {
    height: 400px;
  }
  @include media-breakpoint-up(lg) {
    height: 500px;
    h4 {
      font-size: $dash-header-size-sm * 2;
    }
    p {
      font-size: 1.8em;
    }
  }
}
