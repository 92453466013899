$s3-image-bucket: 'https://s3.amazonaws.com/walden-static-assets/images/';
$recipes-bg-image-url: $s3-image-bucket + 'recipe_landing.png';
$recipes-credit-logo-dark-url: $s3-image-bucket + 'cooks_logo_dark.png';

.mx-atk-content-credit {
  .credit-main {
    background-image: url($recipes-bg-image-url);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .credit-logo {
    height: 60px;
    width: auto;
    background-image: url($recipes-credit-logo-dark-url);
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
  }
  h4 {
    font-size: 1.25em;
  }
}
