$seafood-image-url: 'https://s3.amazonaws.com/walden-static-assets/images/tday2020/Fish.jpg';
$seafood-image-gradient: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

.dashboard-seafood {
  background-position: center top !important;
  background-image: $seafood-image-gradient, url($seafood-image-url);
}

.dashboard-seafood-wrapper {
  @extend .container;
  padding: 0px 15px 0px 15px;
  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}
