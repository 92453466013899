.wd-meat-types-container {
  h3 {
    letter-spacing: 1px;
  }
  p {
    font-size: 1.4em;
    letter-spacing: 1px;
  }
}

.wd-meat-type-error {
  padding: 0.5em 1em;
  margin-bottom: 1em;
  background: $red-2;
  color: $white;
  width: 100%;
  z-index: 10000; // ensure pop-over 'i' doesnt show above error
}

.wd-meat-type {
  overflow: auto;
  z-index: 1000;
  cursor: pointer;
  img {
    width: 100%;
  }
}

.wd-conf-check {
  cursor: pointer;
  z-index: 0;
}

.sticky-pref-col .row,
.sticky-pref-col-even .row {
  vertical-align: middle;
}

.meat-type-image {
  opacity: 0.25;

  &.selected {
    opacity: 1;
  }
}
